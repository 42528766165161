import React from 'react'

import { RenderModules } from 'src/utils/renderModules'
import { SEO } from 'src/components/SEO'

import styled from 'styled-components'
import { PageLink } from 'src/components/link'

import { useStaticQuery, graphql } from "gatsby";
import { device } from 'src/styles/device';

const Main = styled.div`
   display: grid;
  justify-content: stretch;
  min-height: 100%;
  grid-area: Main;
  overflow: hidden;
  grid-template-areas:
    "Shop-Link"
    "Studio-Link";
  color:#fff;

  @media ${device.laptop} {
    display: flex;
    justify-content: center;
    grid-template-areas:
    "Shop-Link Studio-Link";
    > div{
    flex-basis: 50%;
    flex-shrink: 0;
    transition: .8s;
  }
  > div:hover{
    flex-basis: 75%;
  }
  //to give the borders on both main divs adds up to 2px borders to match footer top border
  > div:first-child{
    border-right: 0.5px solid rgba(220, 220, 220, 1);
  }
  > div:last-child{
    border-left: 0.5px solid rgba(220, 220, 220, 1);
  }
  > div:first-child:hover{
    margin-left: 25%;
  }
  > div:last-child:hover{
    margin-right: 25%;
  }
  }
` 

const Txt = styled.div`
// may need to adjust this based on media querys
margin-top:7vh;
font-size:6rem;
a {
 
  font-family: 'ABC Monument Grotesk';
  font-weight: 300;
  font-style: normal;
  letter-spacing: -0.045em;
  color: white;
  text-decoration: none;
  background-image: linear-gradient(currentColor, currentColor);
  background-position: 0% 100%;
  background-repeat: no-repeat;
  background-size: 0% 0.3rem;
  transition: background-size .3s;
  }

a:hover, a:focus {
  background-size: 100% 0.3rem;
}
@media ${device.tablet} {
  margin-top:5vh;
  font-size:8rem;
  }
@media ${device.laptop} {
  margin-top:18.2vh;
  font-size:12rem;
  }
 
`
const CenterContainer = styled.div`
  display: flex; 
	align-items: center; 
	justify-content: center;
`
const ShopBox = styled.div`
background-repeat: no-repeat;
background-size: cover; 
background-image: url(${props => props.img});
/* background:url('https://via.placeholder.com/1024/2A75A9/808080a'); */
background-position: center;
` 

const StudioBox = styled.div`
background-repeat: no-repeat;
background-size: cover; 
background-image: url(${props => props.img});
/* background:url('https://via.placeholder.com/1024/0000FF/808080a'); */
background-position: center;
` 


const ShopLink = styled.div`grid-area: Shop-Link;`
const StudioLink = styled.div`grid-area: Studio-Link;`


export interface PageProps {
  pageContext: {
    modules: []
    slug: string
    main: {
      title: string
    },
    meta: {}
  }
  path?: string
  preview?: boolean
}



const Home = ({
  path,
  pageContext,
  preview = false
}: PageProps) => {
  const {
    modules,
    slug,
    meta
  } = pageContext

  const data = useStaticQuery(graphql`
  {
    allSanitySiteGlobal {
      nodes {
        content {
          globalSettings {
            landingShopBgImage {
              asset {
                fluid {
                  base64
									aspectRatio
									src
									srcSet
									srcWebp
									srcSetWebp
									sizes
                }
                url
              }
            }
            landingStudioBgImage {
              asset {
                fluid {
                  base64
									aspectRatio
									src
									srcSet
									srcWebp
									srcSetWebp
									sizes
                }
                url
              }
            }
          }
        }
      }
    }
  }
  `);

  const url = slug === 'home' ? '' : path
  return (
    <Main>
      {preview && (
        <div className='bcblue ac cw x p1'>This is a Preview</div>
      )}
      <SEO metaInfo={meta} pagePath={url} />
          <ShopBox img={data.allSanitySiteGlobal.nodes[0].content.globalSettings.landingShopBgImage.asset.url}>
            <CenterContainer>
              <Txt>
              <PageLink to='/shop'>SHOP</PageLink>

              </Txt>
            </CenterContainer>
          </ShopBox>
          <StudioBox img={data.allSanitySiteGlobal.nodes[0].content.globalSettings.landingStudioBgImage.asset.url}>
            <CenterContainer>
              <Txt>
              <PageLink to='/studio'>STUDIO</PageLink>
              </Txt>
            </CenterContainer>
          </StudioBox>
        {/* <div>{children}</div> */}
        {RenderModules(modules)}
    </Main>
  )
}

export default Home